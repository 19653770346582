<template>
  <section id="member_reserve_list">
    <div class="container w1300">
      <div class="fliter_outer">
        <MenuClassificationFilter :menuLists="menuLists" />
      </div>
      <div class="list_outer">
        <div class="reserve_list" v-if="appointmentLogs.length > 0">
          <div class="reserve_item" v-for="(item, key) in appointmentLogs" :key="key">
            <!-- <p class="reserve_date">{{ $t("registrationDate") }} | {{ item.date }}</p> -->

            <p class="date-wrap">
              <span class="reserve-label">{{ $t("registrationDate") }} </span>
              <span class="reserve-data">{{ item.date }}</span>
            </p>
            <div class="item_info">
              <!-- <div class="cancel_area">
                <button
                  type="button"
                  class="cancel txt-white small"
                  @click="openCancelReserveLightbox(item)"
                >
                  {{ $t("cancelReserve") }}
                </button>
              </div> -->

              <div class="col100">
                <div class="reserve-list-wrap">
                  <ul class="reserve-list">
                    <li class="reserve-list__item">
                      <p class="small">
                        <span class="reserve-label">{{ $t("registrationCategory") }} </span>
                        <span class="reserve-data">{{ item.devision }}</span>
                      </p>
                    </li>
                    <li class="reserve-list__item">
                      <p class="small">
                        <span class="reserve-label">{{ $t("room") }} </span>
                        <span class="reserve-data">{{ item.clinic }}</span>
                      </p>
                    </li>
                    <li class="reserve-list__item">
                      <p class="small">
                        <span class="reserve-label">{{ $t("yourName") }} </span>
                        <span class="reserve-data">{{ `${$cookies.get("name")}` }}</span>
                      </p>
                    </li>

                    <li class="reserve-list__item">
                      <p class="small">
                        <span class="reserve-label">{{ $t("registrationBranch") }} </span>
                        <span class="reserve-data">{{ clinicName(item) }}</span>
                      </p>
                    </li>

                    <li class="reserve-list__item">
                      <p class="small">
                        <span class="reserve-label">{{ $t("address") }} </span>
                        <span class="reserve-data">{{ item.branch.addr }}</span>
                      </p>
                    </li>
                  </ul>

                  <ul class="reserve-list reserve-list--right">
                    <li class="reserve-list__item">
                      <p class="small">
                        <span class="reserve-label">{{ isTaiwanId ? $t("uid") : $t("pidAndPassportNum") }}
                        </span>
                        <span class="reserve-data">{{ `${$cookies.get("account")}` }}</span>
                      </p>
                    </li>
                    <li class="reserve-list__item">
                      <p class="small">
                        <span class="reserve-label">{{ $t("registrationTime") }} </span>
                        <span class="reserve-data" :class="{ 'doctor--red': item.isDrWang || item.isDrHong || item.isDrChen }">{{ item.time
                        }}</span>
                      </p>
                    </li>
                    <li class="reserve-list__item">
                      <p class="small">
                        <span class="reserve-label">{{ $t("doctor") }} </span>
                        <span class="reserve-data">{{ item.doctorName }}</span>
                        <span class="reserve-data" v-if="getLanguage !== 'en'">{{
                          $t("doctor")
                        }}</span>
                      </p>
                    </li>
                    <li class="reserve-list__item">
                      <p class="small">
                        <span class="reserve-label"> {{ $t("number") }} </span>
                        <span class="reserve-data">{{ item.serial_no }}</span>
                        <span class="reserve-data" v-if="getLanguage !== 'en'">{{ $t("num") }}</span>
                      </p>
                    </li>
                    <!--新增備註 -->
                    <li class="reserve-list__item">
                      <p class="small">
                        <span class="reserve-label">{{ $t("remark") }} </span>
                        <span class="reserve-data" v-if="backendRemark(item)">{{ backendRemark(item) }}</span>
                        <span class="reserve-data" v-else >-</span>
                      </p>
                    </li>
                    <!-- <li class="reserve-list__item"></li> -->
                  </ul>
                </div>

                <div class="reserve-list-wrap">
                  <DoctorNotice class="col100" :doctorParentId="item.doctor_id" />
                </div>

              </div>
              <div class="cancel-wrap" @click="openCancelReserveLightbox(item)">
                <div class="cancel-wrap__box">
                  <img class="cancel-wrap__box__icon" src="@/assets/img/member/click.svg" />
                  <span class="cancel-wrap__box__text">{{ $t("cancelReserve") }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="reserve_list" v-else>
          <p class="no_message">
            {{ $t("noRigistration") }}
          </p>
        </div>
      </div>
    </div>

    <div id="cancel_reserve_lightbox" v-show="cancelReserveLightboxOpen">
      <div class="cover" @click="clickCloseCancelReserveLightbox()"></div>
      <div class="box">
        <div class="text txt-center">
          <h5 class="txt-bold">
            {{ $t("cancelReserve") }}
            <img class="deco_flower" src="@/assets/img/deco_flower.svg" alt="" />
          </h5>
          <div class="reserve_data txt-left" v-if="showAppointmentDataForCancel">
            <p>
              <span class="reserve-label">{{ $t("registrationDate") }} </span>
              <span class="reserve-data">{{ showAppointmentDataForCancel.date }}</span>
            </p>
            <p>
              <span class="reserve-label">{{ $t("registrationTime") }} </span>
              <span class="reserve-data"
                :class="{ 'doctor--red': showAppointmentDataForCancel.isDrWang || showAppointmentDataForCancel.isDrHong || showAppointmentDataForCancel.isDrChen }">{{
                  showAppointmentDataForCancel.time }} </span>
            </p>
            <p>
              <span class="reserve-label">{{ $t("registrationCategory") }} </span>
              <span class="reserve-data">{{ showAppointmentDataForCancel.devision }}</span>
            </p>
            <p>
              <span class="reserve-label">{{ $t("doctor") }} </span>
              <template v-if="getLanguage === 'en'">
                <span class="reserve-data">{{ $t("doctor") }} {{ showAppointmentDataForCancel.doctorName }}</span>
              </template>
              <template v-else>
                <span class="reserve-data">{{ showAppointmentDataForCancel.doctorName }} {{ $t("doctor") }}</span>
              </template>
            </p>
            <p>
              <span class="reserve-label">{{ $t("room") }} </span>
              <span class="reserve-data">{{ showAppointmentDataForCancel.clinic }}</span>
            </p>
            <p>
              <span class="reserve-label">{{ $t("number") }} </span>
              <template v-if="getLanguage !== 'en'">
                <span class="reserve-data">{{ showAppointmentDataForCancel.serial_no }} {{ $t("num") }}</span>
              </template>
              <template v-else>
                <span class="reserve-data">{{ showAppointmentDataForCancel.serial_no }}</span>
              </template>
            </p>
            <p>
              <span class="reserve-label">{{ $t("yourName") }} </span>
              <span class="reserve-data">{{ `${$cookies.get("name")}` }}</span>
            </p>
            <p v-if="isTaiwanId">
              <span class="reserve-label">{{ $t("uid") }} </span>
              <span class="reserve-data">{{ `${$cookies.get("account")}` }}</span>
            </p>
            <p v-else>
              <span class="reserve-label">{{ $t("pidAndPassportNum") }} </span>
              <span class="reserve-data">{{ `${$cookies.get("account")}` }}</span>
            </p>
            <!-- 這邊是新增備註 -->
            <p>
              <span class="reserve-label">{{ $t("remark") }} </span>
              <span class="reserve-data" v-if="showAppointmentDataForCancelRemark(showAppointmentDataForCancel)">{{ showAppointmentDataForCancelRemark(showAppointmentDataForCancel) }}</span>
              <span class="reserve-data" v-else>-</span>
            </p>
          </div>
          <button type="button" class="btn p" @click="clickCancelAppointment(codeForCancel)">
            {{ $t("comfirmCancel") }}
          </button>
        </div>
      </div>
    </div>

    <!-- <div id="cancel_reserve_message_lightbox" v-show="cancelReservemMessageLightboxOpen">
      <div class="cover" @click="clickCloseCancelReserveMessageLightbox()"></div>
      <div class="box">
        <div class="text txt-center">
          <img class="deco_check" src="@/assets/img/member/carry_out.svg" alt="" />
          <h5 class="txt-bold">
            {{ $t("cancelSucsess") }}
            <img class="deco_flower" src="@/assets/img/deco_flower.svg" alt="" />
          </h5>
          <button type="button" class="btn p" @click="clickCloseCancelReserveMessageLightbox()">
            {{ $t("complete") }}
          </button>
        </div>
      </div>
    </div> -->
  </section>
</template>

<script>

import '@/assets/scss/member.scss';
import MenuClassificationFilter from '@/components/MenuClassificationFilter.vue';
import DoctorNotice from '@/components/registration/DoctorNotice.vue';

import { cancelAppointment } from '@/lib/cakeHttp';
import { shiftSettings, timeZH } from '@/utils/const';
import { mapGetters, mapState, mapMutations } from 'vuex';
import { getApiLangCode } from '@/utils/getLangCode';
import { formatCustom } from '@/utils/getWeeks';
import isDoctorWang from '@/mixins/isDoctorWang';
import isDoctorHong from '@/mixins/isDoctorHong';
import isDoctorChen from '@/mixins/isDoctorChen';

export default {
  name: 'MemberReserveList',
  components: {
    MenuClassificationFilter,
    DoctorNotice,
  },
  mixins: [isDoctorWang, isDoctorHong, isDoctorChen],
  props: {
    Classification: Array,
    showAppointmentData: Array,
    isTaiwanId: Boolean
  },
  data() {
    return {
      cancelReserveLightboxOpen: false,
      cancelReservemMessageLightboxOpen: false,
      showAppointmentDataForCancel: null,
      codeForCancel: null,

    };
  },
  computed: {
    ...mapGetters(['getLanguage']),
    ...mapState(['doctors']),

    appointmentLogs() {
      return this.showAppointmentData.map((item) => {
        const date = `${item.date}(${formatCustom(item.date, 'dd')})`;
        const devision = item.devision.includes('婦產科') ? this.$t('ivf') : this.$t('tcm');
        const condition = { 早: 1, 午: 3, 晚: 4, 早診: 1, 午診: 3, 晚診: 4, 早班: 1, 午班: 3, 晚班: 4 }[item.shift_type];

        const { label: shiftName = '', startTime = '', endTime = '', checkIn = '' } =
          shiftSettings[condition] || {};

        const shift = `${shiftName || item.shift_type}(${startTime}-${endTime})${checkIn}`;
        const clinic = this.$t(item.clinic);
        const matchLabel = this.doctors.find(({ child }) => child.includes(item.doctor_id));
        const doctorName = matchLabel?.label || item.doctor_name;

        // 同一個值怎麼這麼多名稱 ... shift_code 配合預約那邊的參數名
        item.shift_code = condition;

        let extraShfitTime = false;
        const isDrWang = this.isDoctorWang(item);
        if (isDrWang) {
          extraShfitTime = 101;
        }

        const isDrHong = this.isDoctorHong(item);
        if (isDrHong) {
          extraShfitTime = 102;
        }
        const isDrChen = this.isDoctorChen(item);
        if (isDrChen) {
          extraShfitTime = 103;
        }
        const time = timeZH(item.shift_code, extraShfitTime);

        return { ...item, date, devision, shift, clinic, doctorName, time, isDrWang, isDrHong };
      });
    },
    menuLists() {
      return [
        {
          label: '',
          lists: this.Classification.map((item, uuid) => ({ ...item, title: item.linkName, uuid }))
        }
      ];
    }
  },

  watch: {
    // codeForCancel(val) {
    //   // const temp = val;
    //   this.showAppointmentData.forEach((elem) => {
    //     if (val === elem.預掛識別碼) {
    //       this.showAppointmentDataForCancel = elem;
    //     }
    //   });
    // },
    // showAppointmentData() {
    //   this.showAppointmentData.forEach((form, i) => {
    //     switch (form.科別) {
    //       case '生殖醫學':
    //         this.showAppointmentData[i].科別 = this.$t('ivf');
    //         break;
    //       case '中醫':
    //         this.showAppointmentData[i].科別 = this.$t('tcm');
    //         break;
    //       default:
    //         break;
    //     }
    //     switch (form.班別) {
    //       case '上午':
    //         this.showAppointmentData[i].班別 = this.$t('am');
    //         break;
    //       case '下午':
    //         this.showAppointmentData[i].班別 = this.$t('pm');
    //         break;
    //       case '晚上':
    //         this.showAppointmentData[i].班別 = this.$t('night');
    //         break;
    //       default:
    //         break;
    //     }
    //     switch (form.班別) {
    //       case '上午':
    //         this.showAppointmentData[i].班別 = this.$t('am');
    //         break;
    //       case '下午':
    //         this.showAppointmentData[i].班別 = this.$t('pm');
    //         break;
    //       case '晚上':
    //         this.showAppointmentData[i].班別 = this.$t('night');
    //         break;
    //       case '晚間':
    //         this.showAppointmentData[i].班別 = this.$t('betweenNight');
    //         break;
    //       default:
    //         break;
    //     }
    //     switch (form.診別) {
    //       case '一診':
    //         this.showAppointmentData[i].診別 = this.$t('roomOne');
    //         break;
    //       case '二診':
    //         this.showAppointmentData[i].診別 = this.$t('roomTwo');
    //         break;
    //       case '三診':
    //         this.showAppointmentData[i].診別 = this.$t('roomThree');
    //         break;
    //       case '四診':
    //         this.showAppointmentData[i].診別 = this.$t('roomFour');
    //         break;
    //       case '五診':
    //         this.showAppointmentData[i].診別 = this.$t('roomFive');
    //         break;
    //       case '六診':
    //         this.showAppointmentData[i].診別 = this.$t('roomSix');
    //         break;
    //       case '七診':
    //         this.showAppointmentData[i].診別 = this.$t('roomSeven');
    //         break;
    //       case '八診':
    //         this.showAppointmentData[i].診別 = this.$t('roomEight');
    //         break;
    //       default:
    //         break;
    //     }
    //   });
    // },
  },
  methods: {
    ...mapMutations(['toggleShowMsg', 'setMsgInfo']),
    clinicName(item) {
      // 要取後端返回的語言 code，所以不是拿網站判斷的
      return item.branch.translate[getApiLangCode()];
    },
    // 這邊也是要從後端返回語言(備註)
    backendRemark(item) {
      return item.remark[getApiLangCode()];
    },
    // 後端返回語言(備註)
    showAppointmentDataForCancelRemark(item) {
      return item.remark[getApiLangCode()];
    },
    openCancelReserveLightbox(item) {
      this.cancelReserveLightboxOpen = true;
      this.showAppointmentDataForCancel = item;
      this.codeForCancel = item;
    },
    clickCloseCancelReserveLightbox() {
      this.cancelReserveLightboxOpen = false;
    },
    // clickCloseCancelReserveMessageLightbox() {
    //   this.cancelReservemMessageLightboxOpen = false;
    // },
    clickCancelAppointment(item) {
      cancelAppointment(
        item,
        (/* response */) => {
          this.$emit('updateAppointment');
          this.cancelReserveLightboxOpen = false;
          // this.cancelReservemMessageLightboxOpen = true;

          this.toggleShowMsg(true);
          this.setMsgInfo({
            succeed: true,
            desc: this.$t('cancelSucsess'),
            callback: () => { },
            triggerCallbackOnOverlay: true,
            triggerCallbackOnConfirmBtn: true
          });
        },
        (message) => {
          this.toggleShowMsg(true);
          this.setMsgInfo({
            succeed: true,
            desc: message,
            callback: () => {
              this.cancelReserveLightboxOpen = false;
            },
            triggerCallbackOnOverlay: true,
            triggerCallbackOnConfirmBtn: true
          });

          // this.$customSWAL({
          //   icon: 'error',
          //   title: message,
          //   thenFunc: () => {
          //     this.cancelReserveLightboxOpen = false;
          //   }
          // });
        }
      );
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/helper/_variable.scss";

.cancel-table {
  td {
    p {
      white-space: nowrap;
    }
  }
}

.item_info {
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  background-color: #fff;
  height: 100%;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.reserve-list-wrap {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;

  &:empty {
    display: none;
  }

  @media screen and (min-width: 600px) {
    display: flex;
    padding: 20px 200px 20px 50px;
  }
}

.reserve-list {
  @media screen and (min-width: 600px) {
    flex: 1;
    width: 50%;
  }

  &--right {
    margin-left: 0px;

    @media screen and (min-width: 600px) {
      margin-left: 50px;
    }
  }

  &__item {
    padding: 5px 0;
  }
}

.cancel-wrap {
  background-color: $color-theme;
  height: 100%;
  right: 0;
  width: 150px;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &__box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__icon {
      width: 43px;
    }

    &__text {
      font-size: 16px;
      color: #fff;
      margin-top: 10px;
      letter-spacing: 1px;
      text-align: center;
    }
  }

  @media screen and (max-width: 600px) {
    position: static;
    width: 100%;
    box-sizing: border-box;
    padding: 30px 0;
  }
}

.date-wrap {
  margin-bottom: 10px;
}

.reserve-label {
  color: $color-dark_gray;
  letter-spacing: 1px;
  padding-right: 20px;
  position: relative;
  font-size: 14px;

  &::after {
    content: "";
    width: 1px;
    height: 12px;
    border-radius: 1px;
    background-color: $color-dark_gray;
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 11px;
    transform: translateY(-50%);
  }
}

.reserve-data {
  font-size: 14px;
  color: #090909;
  letter-spacing: 1px;
}

.doctor {
  &--red {
    color: #ff2d2d;
  }
}
</style>
