<template>
  <div class="member">
    <BannerKv :titleEn="BannerKvData.titleEn" :titleZh="BannerKvData.titleZh" />
    <MemberReserveList
      :Classification="Classification"
      :showAppointmentData="showAppointmentData"
      v-on:updateAppointment="getAllAppointment"
      :isTaiwanId="isTaiwanId"
    />
  </div>
</template>

<script>
import '@/assets/scss/news.scss';
import BannerKv from '@/components/BannerKv.vue';
import MemberReserveList from '@/components/member/MemberReserveList.vue';
import { isNationalIdentificationNumberValid } from 'taiwan-id-validator';
import { checkAppointment, getMemberProfileV2 } from '@/lib/cakeHttp';
import { mapMutations } from 'vuex';
import { getUrlLangCode } from '@/utils/getLangCode';

export default {
  name: 'MemberHome',

  components: {
    BannerKv,
    MemberReserveList
  },
  data() {
    return {
      isTaiwanId: false,
      memberProfile: '',
      BannerKvData: {
        titleEn: 'WELCOME',
        titleZh: this.$t('helloGuest', [this.$cookies.get('name')])
      },
      Classification: [
        {
          path: `/${getUrlLangCode()}/memberhome`,
          linkName: this.$t('myregistration')
        },
        {
          path: `/${getUrlLangCode()}/memberdata`,
          linkName: this.$t('myInfo')
        }
      ],
      showAppointmentData: []
    };
  },
  methods: {
    ...mapMutations(['toggleShowMsg', 'setMsgInfo', 'logIn']),
    getAllAppointment() {
      this.showAppointmentData = [];
      if (this.$cookies.get('api_token')) {
        checkAppointment(
          (response) => {
            this.showAppointmentData = response?.data;
          },
          (response) => {
            this.$customSWAL({ icon: 'error', title: response });
          }
        );
      } else {
        this.toggleShowMsg(true);
        this.setMsgInfo({
          succeed: false,
          desc: this.$t('pleaseLogin'),
          callback: () => {
            this.$router.push({ name: 'Login' });
          },
          triggerCallbackOnOverlay: true,
          triggerCallbackOnConfirmBtn: true,
        });

        // this.$customSWAL({
        //   icon: 'error',
        //   title: this.$t('pleaseLogin'),
        //   thenFunc: () => {
        //     this.$router.push({
        //       name: 'Login',
        //     });
        //   },
        // });
      }
    },
    getThisMemberProfile() {
      this.showAppointmentData = [];
      if (this.$cookies.get('api_token')) {
        getMemberProfileV2(
          (response) => {
            this.logIn({
              member: response
            });

            this.memberProfile = response;
            if (isNationalIdentificationNumberValid(this.memberProfile.account)) {
              this.isTaiwanId = true;
            }
          },
          (response) => {
            this.$customSWAL({ icon: 'error', title: response });
          }
        );
      } else {
        this.toggleShowMsg(true);
        this.setMsgInfo({
          succeed: false,
          desc: this.$t('pleaseLogin'),
          callback: () => {
            this.$router.push({ name: 'Login' });
          },
          triggerCallbackOnOverlay: true,
          triggerCallbackOnConfirmBtn: true,
        });
      }
    }
  },
  mounted() {
    this.getAllAppointment();
    this.getThisMemberProfile();
  }
};
</script>
