export default {
  methods: {
    // 奇怪的醫生，詳細看這張單 https://howdesign.atlassian.net/browse/NUWA-2097
    isDoctorChen(appointmentData) {
      let isDoctorChen;
      if (this.$route.name === 'MemberHome') {
        isDoctorChen = appointmentData?.doctor_name === '陳菁徽';
      } else {
        isDoctorChen = appointmentData?.doctor?.name.substr(0, 3) === '陳菁徽';
      }

      const isEveningShift = Number(appointmentData?.shift_code) === 4;
      const isWeekDay = [1, 3].includes(new Date(appointmentData?.date).getDay());
      const isIvf = appointmentData?.branch_no === '350102F728';

      // console.log(isDoctorChen, isEveningShift, isWeekDay, isIvf);

      return isDoctorChen && isEveningShift && isWeekDay && isIvf;
    },
  },
};
