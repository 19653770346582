<template>
  <div class="doctor-notice" v-if="remark">
    <p class="title">*注意事項</p>
    <p class="text">{{ remark }}</p>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getApiLangCode } from '@/utils/getLangCode';

export default {
  name: 'DoctorNotice',
  props: {
    doctorParentId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState(['doctorMapping', 'appointmentData']),
    remark() {
      const doctorParentId = this.$props.doctorParentId || this.appointmentData?.doctor?.parent_id || '';
      const doctorRemark = this.doctorMapping[doctorParentId]?.remark;

      if (!doctorRemark) {
        return '';
      }
      const langCode = getApiLangCode();
      return doctorRemark[langCode];
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/other/mixin.scss";
@import "@/assets/scss/other/color.scss";

.doctor-ads {}

.title {
  @include font-style($color-theme, 16, 400, 1px);
}

.text {
  margin-top: 10px;
  @include font-style($c-black, 16, 400, 1px);
}
</style>
