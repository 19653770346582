export default {
  methods: {
    // 奇怪的醫生，詳細看這張單 https://howdesign.atlassian.net/browse/NUWA-1495
    isDoctorHong(appointmentData) {
      let isDoctorHong;
      if (this.$route.name === 'MemberHome') {
        isDoctorHong = appointmentData?.doctor_name === '洪子渝';
      } else {
        isDoctorHong = appointmentData?.doctor?.name.substr(0, 3) === '洪子渝';
      }

      const isMorning = Number(appointmentData?.shift_code) === 1;
      const isWeekDay = [2, 5].includes(new Date(appointmentData?.date).getDay());
      const isIvf = appointmentData?.branch_no === '3532018397';

      // console.log(isDoctorHong, isMorning, isWeekDay, isIvf);

      return isDoctorHong && isMorning && isWeekDay && isIvf;
    },
  },
};
