export default {
  methods: {
    // 奇怪的醫生，詳細看這張單 https://howdesign.atlassian.net/browse/NUWA-1495
    isDoctorWang(appointmentData) {
      let isDoctorWang;
      if (this.$route.name === 'MemberHome') {
        isDoctorWang = appointmentData?.doctor_name === '王懷麟';
      } else {
        isDoctorWang = appointmentData?.doctor?.name.substr(0, 3) === '王懷麟';
      }

      const isEveningShift = Number(appointmentData?.shift_code) === 4;
      const isWeekDay = [2, 4].includes(new Date(appointmentData?.date).getDay());
      const isIvf = appointmentData?.branch_no === '350102F728';

      // console.log(isDoctorWang, isEveningShift, isWeekDay, isIvf);

      return isDoctorWang && isEveningShift && isWeekDay && isIvf;
    },
  },
};
